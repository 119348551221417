import * as React from 'react';
import { useEffect, useRef } from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import Logo from '../svg/logo';
import LogoClip from '../svg/logo-clip';
import { EnteringSmall, LeavingSmall } from '../components/cursor';
import { scroll } from '../components/infinite';
import MediaItem from '../components/media-item';

const IndexPage = ({ data }) => {
	const clients = data.clients.nodes;
	const homepage = data.homepage;

	const handleScrollRef = useRef();
	useEffect(() => {
		handleScrollRef.current = () => {
			scroll();
		};

		window.addEventListener('scroll', handleScrollRef.current);

		return () => {
			// This function will be called when the component is unmounted
			window.removeEventListener('scroll', handleScrollRef.current);
		};
	}, []);

	return (
		<Layout>
			<HelmetDatoCms
				seo={data.homepage.seoMetaTags}
				bodyAttributes={{
					class: 'dark-bg index-page',
				}}
			/>
			<nav className='bottom-nav'>
				<Link
					to='/'
					activeClassName='active'
					className='bottom-nav-item subnavigation'
					onMouseEnter={EnteringSmall}
					onMouseLeave={LeavingSmall}
				>
					{homepage.pageName}
				</Link>
				<Link
					to='/work'
					activeClassName='active'
					className='bottom-nav-item subnavigation'
					onMouseEnter={EnteringSmall}
					onMouseLeave={LeavingSmall}
				>
					{data.work.pageName}
				</Link>
			</nav>
			<main key='main'>
				<section>
					<section className='media-scrollable scrollable'>
						<div className='media-content content'>
							{clients.map((client) => (
								<MediaItem client={client} />
							))}
						</div>
						{/* Duplicate Content for Infinite Scroll */}
						<div className='media-content content'>
							{clients.map((client) => (
								<MediaItem client={client} />
							))}
						</div>
						{/* End of duplicate content */}
					</section>
					<div className='large-logo'>
						<div className='large-logo-container'>
							<div className='logo-blur'></div>
							<LogoClip />
							<Logo />
						</div>
						{homepage.blurb && (
							<p className='lede'>{homepage.blurb}</p>
						)}
					</div>
					<div className='hover-image-container'>
						{/* <div className="scrim"></div> */}
						{clients.map((client) => (
							<img
								className='cover-image hover-image'
								id={`hover-image-${client.slug}`}
								src={client.image.background}
								alt=''
							/>
						))}
					</div>
				</section>
			</main>
		</Layout>
	);
};

export default IndexPage;

export const query = graphql`
	query {
		homepage: datoCmsHomepage {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			blurb
			pageName
		}
		work: datoCmsWork {
			pageName
		}
		clients: allDatoCmsClient(sort: { fields: position, order: ASC }) {
			nodes {
				slug
				name
				image {
					featured: url(imgixParams: { w: "840" })
					background: url(
						imgixParams: {
							w: "1400"
							blendColor: "2A292D"
							blendMode: "normal"
							blendAlpha: 56
						}
					)
				}
				vimeo
				logo
			}
		}
	}
`;
