import * as React from 'react'

const LogoClip = () => {
    return (
        <svg class="logo-clip" width="0" height="0">
            <defs>
                <clipPath id="logo-clip" clipPathUnits="objectBoundingBox">
                    <path d="M0.0687782 0.965631C0.0356154 0.965631 0.00928983 0.928674 0.00463867 0.682587H0.0487782C0.0506852 0.726066 0.056034 0.737805 0.0772898 0.737805C0.100313 0.737805 0.103476 0.721283 0.103615 0.675196V0.669979C0.103615 0.635196 0.101708 0.623892 0.094034 0.621283L0.0526387 0.608674C0.0162666 0.596935 0.00724332 0.533892 0.00724332 0.353457V0.340848C0.00724332 0.10737 0.0243596 0.043457 0.0715224 0.043457H0.0796154C0.112918 0.043457 0.139615 0.0817179 0.143755 0.339544H0.0994759C0.097848 0.290848 0.0930573 0.271718 0.0734294 0.271718C0.0538015 0.271718 0.0491968 0.286935 0.0491968 0.336935V0.339544C0.0491968 0.374327 0.0511038 0.385631 0.0587782 0.38824L0.0996154 0.400848C0.136081 0.412587 0.144964 0.471283 0.144964 0.643022V0.652153C0.144964 0.902153 0.127848 0.966066 0.0806852 0.966066H0.0687782V0.965631Z" fill="#2A292D"/>
                    <path d="M0.220018 0.965577C0.171088 0.965577 0.152344 0.866882 0.152344 0.529925V0.478621C0.152344 0.141664 0.171134 0.0429688 0.220018 0.0429688H0.234251C0.283181 0.0429688 0.301925 0.141664 0.301925 0.478621V0.529925C0.301925 0.866882 0.283134 0.965577 0.234251 0.965577H0.220018ZM0.260018 0.519925V0.49036C0.260018 0.348186 0.256576 0.296882 0.228483 0.296882H0.225739C0.197646 0.296882 0.194204 0.348186 0.194204 0.49036V0.519925C0.194204 0.660795 0.197646 0.712099 0.225739 0.712099H0.228483C0.256576 0.710795 0.260018 0.660795 0.260018 0.519925Z" fill="#2A292D"/>
                    <path d="M0.619315 0.965684C0.586153 0.965684 0.559827 0.928728 0.555176 0.682641H0.599315C0.601222 0.726119 0.606571 0.737858 0.627827 0.737858C0.65085 0.737858 0.654013 0.721337 0.654153 0.67525V0.670032C0.654153 0.63525 0.652246 0.623945 0.644571 0.621337L0.603176 0.608728C0.56685 0.596989 0.557827 0.53438 0.557827 0.353945V0.341337C0.557827 0.108293 0.574943 0.0439453 0.622106 0.0439453H0.630199C0.663501 0.0439453 0.690199 0.0822062 0.694339 0.340032H0.650059C0.648432 0.291337 0.643641 0.272206 0.624013 0.272206C0.604385 0.272206 0.59978 0.287424 0.59978 0.337424V0.340032C0.59978 0.374815 0.601687 0.386119 0.609362 0.388728L0.650199 0.401337C0.686664 0.413076 0.695548 0.471771 0.695548 0.64351V0.652641C0.695548 0.902641 0.678432 0.966554 0.631269 0.966554H0.619362L0.619315 0.965684Z" fill="#2A292D"/>
                    <path d="M0.768358 0.965577C0.719428 0.965577 0.700684 0.866882 0.700684 0.529925V0.478621C0.700684 0.141664 0.719474 0.0429688 0.768358 0.0429688H0.782591C0.831521 0.0429688 0.850265 0.141664 0.850265 0.478621V0.529925C0.850265 0.866882 0.831474 0.965577 0.782591 0.965577H0.768358ZM0.808358 0.519925V0.49036C0.808358 0.348186 0.804916 0.296882 0.776823 0.296882H0.774079C0.745986 0.296882 0.742544 0.348186 0.742544 0.49036V0.519925C0.742544 0.660795 0.745986 0.712099 0.774079 0.712099H0.776823C0.804916 0.710795 0.808358 0.660795 0.808358 0.519925Z" fill="#2A292D"/>
                    <path d="M0.858887 0.953109V0.0561523H0.94605C0.983073 0.0561523 0.996608 0.129196 0.996608 0.376587V0.394413C0.996608 0.617457 0.983305 0.714848 0.94605 0.714848H0.900003V0.953109H0.858887ZM0.900003 0.4805H0.940282C0.952887 0.4805 0.955491 0.458761 0.955491 0.390935V0.3805C0.955491 0.312674 0.952887 0.290935 0.940282 0.290935H0.900003V0.4805V0.4805Z" fill="#2A292D"/>
                    <path d="M0.549846 0.387891H0.520079C0.496404 0.387891 0.493474 0.4405 0.493474 0.577022V0.952239H0.45166V0.0561523H0.493474V0.196587H0.494916C0.500869 0.084848 0.513521 0.0561523 0.534358 0.0561523H0.549846V0.387891Z" fill="#2A292D"/>
                    <path d="M0.352454 0.0483398V0.507905C0.352454 0.644862 0.363663 0.663557 0.376872 0.663557C0.391152 0.663557 0.401198 0.610949 0.401198 0.474427V0.0483398H0.443198V0.944427H0.401198V0.805731H0.399756C0.393803 0.915731 0.375896 0.961383 0.358686 0.961383C0.348175 0.961383 0.310547 0.912253 0.310547 0.572688V0.0483398H0.352454V0.0483398Z" fill="#2A292D"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default LogoClip