import * as React from 'react'
import { useRef } from "react"
import { Link } from 'gatsby'
import { EnteringView, LeavingView } from "../components/cursor"

const MediaItem = ({client}) => {
    const videoRef = useRef()

    const playVideoOnHover = () => {
        videoRef.current.play()
    }
    const stopVideoOnMouseLeave = () => {
        videoRef.current.pause()
    }
    const handleMouseEnter = () => {
        playVideoOnHover();
        EnteringView();
    }
    const handleMouseLeave = () => {
        stopVideoOnMouseLeave();
        LeavingView();
    }
    return (
        <div className="media-section">
            <Link to={`/client/${client.slug}`} id={client.slug} className='client-item media-item js-media-item' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className="logo-container" dangerouslySetInnerHTML={{ __html: client.logo }}></div>
                <div className="grid-media grid-media-image">
                <img className="cover-image " src={client.image.featured} alt='' />
                </div>
                <div className="grid-media grid-media-video">
                <video ref={videoRef} poster={client.image.featured} className="cover-image client-video" loop muted preload="metadata" playsInline>
                    <source src={`${client.vimeo}#t=0.1`} type="video/mp4"/>
                </video>
                </div>
            </Link>
        </div>
    )
}

export default MediaItem