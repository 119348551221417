export function scroll() {
    let target = window.scrollY,
      content = document.querySelector(".scrollable");
  
    // // Scroll the page 1px every second
    // target += 1;
    // window.scrollTo(0, target);
  
    // If the user has scrolled to the end of the page, reset the scroll position
    if (target >= content.offsetHeight / 2) {
      target = 1;
      window.scrollTo(0, target);
    }

  }
